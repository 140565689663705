/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const React = require('react');
const {default: Layout} = require('./src/components/Layout');
const { WrapRootElement } = require('./src/components/Provider');

exports.wrapRootElement = ({ element }) => {
  return React.createElement(WrapRootElement, { element });
};

exports.wrapPageElement = ({ element, props }) => {
  console.log("wrapPageElement, props", props);
  return (<Layout>{element}</Layout>);
};
