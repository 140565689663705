import React, {useEffect} from 'react'
import {navigate} from "gatsby"
import { ThemeProvider, merge } from 'theme-ui'
import { MDXProvider, useMDXComponents } from '@mdx-js/react'
import { toTheme } from '@theme-ui/typography';

import kirkham from "typography-theme-kirkham";

import futureTheme from "@theme-ui/preset-future"

const kirkhamTheme = toTheme(kirkham);

// compare https://github.com/system-ui/theme-ui/blob/develop/packages/gatsby-plugin-theme-ui/src/provider.js#L28

// copied from the theme generator
const otherTheme = {
  space: [
    0,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: '"Avenir Next", Helvetica, Arial, sans-serif'
  },
  fontSizes: [
    12,
    14,
    16,
    20,
    24,
    32,
    48,
    64,
    96
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  colors: {
    text: '#636363',
    background: '#ffffff',
    primary: '#318FB6',
    primaryActive: '#38B0E3',
    secondary: '#d9d9d9',
    secondaryActive: '#c6c6c6',
    muted: '#f6f6f6'
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    },
    a: {
      color: 'primary'
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    }
  },
  forms: {
    error: {
      color: 'red',
      borderWidth: '1px 1px 1px 10px',
    }
  },
  buttons: {
    primary: {
      borderRadius: '99px',
      // color: 'text',
      color: 'background',
      bg: 'primary',
      '&:hover': {
        // color: 'background',
        bg: 'primaryActive',
        cursor: 'pointer'
      },
      '&:disabled': {
        bg: 'muted',
        cursor: 'default'
      }
    },
    secondary: {
      borderRadius: '99px',
      color: 'background',
      bg: 'secondary',
      '&:hover': {
        bg: 'secondaryActive',
        cursor: 'pointer'
      }
    },
    formToggle: {
      transition: '0.3s all',
      color: 'text',
      bg: 'background',
      '&:hover': {
        color: 'primaryActive',
        cursor: 'pointer'
      }
    },
  },
  cards: {
    primary: {
      padding: 2,
      color: 'background',
      bg: 'primary',
      '&:hover': {
        bg: 'primaryActive',
        cursor: 'pointer'
      }
    },
    secondary: {
      padding: 2,
      color: 'background',
      bg: 'secondary',
      '&:hover': {
        bg: 'secondaryActive',
        cursor: 'pointer'
      }
    },
    listing: {
      padding: 2,
    },
  },
  text: {
    error: {
      color: 'red'
    }
  },
  images: {
    listingThumb: {
      maxWidth: 100,
      maxHeight: 100,
      width: 'auto',
      height: 'auto',
      borderRadius: 4
    },
    listingCard: {
      objectFit: 'cover',
      maxHeight: 400,
      '@media screen and (min-width: 592px)': {
        borderRadius: 4,
        maxWidth: '752px',
      },
      '@media screen and (max-width: 591px)': {
        minWidth: '100vw',
        marginLeft: '-8px',
        marginRight: '-8px',
        marginTop: '-8px',
      },
    }
  },
};

const theme = {
  ...futureTheme,
  primary: {
    color: 'background',
    bg: 'primary',
    '&:hover': {
      bg: 'text',
      cursor: 'pointer'
    }
  },
  secondary: {
    color: 'background',
    bg: 'secondary',
    '&:hover': {
      bg: 'text',
      cursor: 'pointer'
    }
  },
  colors: {
    ...futureTheme.colors,
    // text: 'green',
    modes: {
      dark: {
        ...futureTheme.colors.modes.dark,
        // text: 'yellow',
      }
    }
  },
  fonts: {
    ...futureTheme.fonts,
    body: 'system-ui, sans-serif',
  },
  styles: {
    ...futureTheme,
    root: {
      fontFamily: 'body',
      fontWeight: 'body'
    },
  },
};

const components = {}; // could define components here, compare 
                       // https://github.com/system-ui/theme-ui/blob/develop/packages/gatsby-plugin-theme-ui

const Root = ({ children }) => {
  const fullTheme = otherTheme;
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser) {
      const {location} = window;
      const match = location.host.match(/^(www\.)(.*)$/);
      if (match) {
        const newLocation = location.href.replace(/\/\/www\./, "//")
        location.replace(newLocation);
      }
    }
  }, [isBrowser]);

  return (
    <ThemeProvider theme={fullTheme}>
      <MDXProvider
        components={(useMDXComponents(components))}
      >
        {children}
      </MDXProvider>
    </ThemeProvider>
  )
}

export const WrapRootElement = ({ element }) => {
  return <Root>{element}</Root>
}
