import React from "react";
import {navigate} from "gatsby";
import {Button, Flex} from "theme-ui";
import Sell from "@mui/icons-material/Sell";

const AddListingButton = () => {

  // when adding a listing, we don't need the "add listing" button
  if (global.location?.pathname.match('^\/listings\/new')) {
    return null;
  }

  return <Button variant="primary" onClick={() => {
    navigate('/listings/new');
  }} >
    <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
      <Sell />
      &nbsp;Add Listing
    </Flex>
  </Button>;
};

export default AddListingButton;
